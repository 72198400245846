import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../../components/Layout'
import ServiceFooter from '../../components/ServiceFooter'

const Qtnet = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Layout>
      <Helmet>
        <html className="temp" lang="ja" />
        <title>{`QT-net | ${title}`}</title>
        <meta
          name="description"
          content="グローバル・ネットワークシステム対応の精算機によるQT-netコインパ予約・QT-net会員サービス・法人会員サービス・駐車場検索サイトをご紹介いたします。"
        />
      </Helmet>

      <div className="common-header clearfix">
        <p className="category pc-only">製品・サービス</p>
        <h1>QT-netサービス案内</h1>
        <p className="category sp-only">製品・サービス</p>

        <div className="breadcrumb">
          <li>
            <Link to={`/`}>トップページ</Link>
          </li>
          /
          <li>
            <Link to={`/service`}>製品・サービス</Link>
          </li>
          /<li>QT-netサービス案内</li>
        </div>
      </div>

      <div className="main-wrapper qtnet">
        <div className="row">
          <div className="container empty"></div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h2>QT-net</h2>
          </div>
        </div>

        <div className="row">
          <div className="container top">
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/common/service-qtnet-01.webp"
              />
              <img
                className="qtnet-01"
                src="/assets/images/common/service-qtnet-01.png"
                height="895"
                alt=""
              />
            </picture>

            <h5>ここがアイテック！</h5>

            <div className="summary full">
              <ul>
                <li>
                  グローバル・ネットワークシステム対応の精算機により、スマホアプリやQT-netカードによるクレジット決済が可能。QT-netポイントも貯まります。
                </li>
                <li>
                  月単位で駐車場利用料金を一括精算でき、利用状況の管理を容易にする法人サービスも。
                </li>
                <li>
                  システム活用により、駐車場運営会社をまたいだネットワークサービスの展開も可能に。
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h2>QT-net コインパ予約</h2>
          </div>
        </div>

        <div className="row">
          <div className="container cont-01">
            <h4>いつでもどこへでも外出できる街を目指します</h4>

            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/common/service-qtnet-02.webp"
              />
              <img
                className="qtnet-02"
                src="/assets/images/common/service-qtnet-02.png"
                height="956"
                alt=""
              />
            </picture>

            <p>
              車いす対応駐車区画も予約ができる！！
              <br />
              お出かけ前に、スマホで車いす対応駐車区画を簡単予約！！予約のないときは、一般の方も時間貸し駐車区画として利用することができます。
            </p>

            <h5>ここがアイテック！</h5>

            <div className="summary full">
              <ul>
                <li>
                  スマホのアプリから、駐車場を検索し予約が出来ます。駐車場を探す手間も省けます。（ご利用にはQT-net会員登録が必要です）
                </li>
                <li>車に乗ったまま、精算も可能。(クレジット登録が必要です）</li>
                <li>
                  精算は、車に乗ったまま、またはアプリ・QT-netカードをかざすだけ。
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h3>駐車場はこれからこうなる。</h3>
          </div>
        </div>

        <div className="row">
          <div className="container cont-02">
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/pc/service-qtnet-03.webp"
              />
              <img
                className="pc-only qtnet-03"
                src="/assets/images/pc/service-qtnet-03.png"
                alt=""
              />
            </picture>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/sp/service-qtnet-03.webp"
              />
              <img
                className="sp-only qtnet-03"
                src="/assets/images/sp/service-qtnet-03.png"
                height="932"
                alt=""
              />
            </picture>

            <p>
              グローバル・ネットワークシステムは、対応精算機とデータセンターをインターネット回線でつなぎ、さまざまな情報の収集・管理を行う、新しい駐車場システムです。これにより、料金のキャッシュレス決済や利用ポイントごとのサービスの提供が可能になります。
            </p>

            <h5>データセンター</h5>

            <p>
              サーバーが二重化されており、すべてのデータを二重に保存。24時間ノンストップ体制を実現しています。
            </p>

            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/pc/service-qtnet-04.webp"
              />
              <img
                className="pc-only qtnet-04"
                src="/assets/images/pc/service-qtnet-04.png"
                alt=""
              />
            </picture>

            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/sp/service-qtnet-04.webp"
              />
              <img
                className="sp-only qtnet-04"
                src="/assets/images/sp/service-qtnet-04.png"
                height="1336"
                alt=""
              />
            </picture>

            <p>
              システムのメリットを最大化するために。QT-netの名のもとに、駐車場をネットワークで結び、メンバーならではの駐車場サービスを広げます。
            </p>

            <h5>QT-netとは？</h5>
            <div className="img-block">
              <picture>
                <source
                  type="image/webp"
                  srcset="/assets/images/common/service-qtnet-05.webp"
                />
                <img
                  className="qtnet-05"
                  src="/assets/images/common/service-qtnet-05.png"
                  height="728"
                  alt=""
                />
              </picture>
            </div>
            <div className="desc-block">
              <p>
                Quick Touch
                Netは、グローバル・ネットワークシステムを活用する駐車場運営会社が加盟。運営会社の枠を超えたサービスを展開する、オープンネットワークです。
                <br />
                参加する運営会社が増えるほど、メリットは大きく広がっていきます。
                <br />
                <br />
                QT-netメンバーの方は、システム対応精算機のある駐車場なら、どこでも同様のサービスが受けられます。
              </p>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h2>QT-net 会員サービス</h2>
          </div>
        </div>

        <div className="row">
          <div className="container cont-03">
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/common/service-qtnet-06.webp"
              />
              <img
                className="qtnet-06"
                src="/assets/images/common/service-qtnet-06.png"
                height="654"
                alt=""
              />
            </picture>

            <ul className="small-points">
              <li>
                スマホアプリやホームページから会員登録（登録手数料、年会費無料）。
              </li>
              <li> 駐車場利用料金のクレジット決済も可能です。</li>
              <li>
                QT-netの駐車場を利用するたびにポイントを貯めることができます。
              </li>
            </ul>

            <h5>ここがアイテック！</h5>

            <div className="summary full">
              <ul>
                <li>
                  利用料金100円につき1円以上のポイントを提供。（ポイントは駐車場により異なります)
                </li>
                <li>
                  ご利用1000ポイントより、キャッシュバックが受けられます。他のポイントにも交換可能。
                </li>
                <li>
                  事前にクレジットカードを登録しておけば、アプリ・QT-netカードいずれの精算も現金いらず。クレジットカードで精算できます。
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h2>QT-net 法人会員サービス</h2>
          </div>
        </div>

        <div className="row">
          <div className="container cont-04">
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/common/service-qtnet-07.webp"
              />
              <img
                className="qtnet-07"
                src="/assets/images/common/service-qtnet-07.png"
                height="460"
                alt=""
              />
            </picture>

            <h5>ここがアイテック！</h5>

            <div className="summary full">
              <ul>
                <li>
                  法人カードを発行（利用手数料、発行手数料、年間費無料）。
                </li>
                <li>
                  QT-net駐車場ならどこでも、キャッシュレスで利用可能です。
                </li>
                <li>
                  月単位で駐車場利用料金を一括精算。（料金の回収は、アイテックが代行いたします）
                </li>
                <li>
                  立て替え払いによる従業員の方の金銭的なご負担や、領収書の処理などの面倒をなくします。
                </li>
                <li>
                  管理者の方は、ご利用明細の活用により、車両、社員ごとの利用状況を把握することが可能になり、見えないコスト削減につながります。
                </li>
                <li>暗証番号により、カード紛失時の不正使用を防ぎます。</li>
                <li>
                  違法駐車をなくし、コンプライアンス（法令遵守）を徹底できます。
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h2>QT-net駐車場検索サイト</h2>
          </div>
        </div>

        <div className="row">
          <div className="container cont-05">
            <p>
              アプリやブラウザでQT-net駐車場が検索できます。混雑状況の確認もできます。
            </p>

            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/common/service-qtnet-08.webp"
              />
              <img
                className="qtnet-08"
                src="/assets/images/common/service-qtnet-08.png"
                height="747"
                alt=""
              />
            </picture>

            <h4>
              ユーザーやニーズを先取ったサービスの提供により、リピーター増とともに、より大きな集客効果が期待できます。
            </h4>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h2>QT-net運営会社様向け</h2>
          </div>
        </div>

        <div className="row">
          <div className="container cont-06">
            <p>加盟店様専用Webサイトにより情報を提供。</p>
            <p>駐車場ごとの月々の情報を照会・検索できます。</p>

            <ul className="small-points">
              <li>クレジット決済利用金額</li>
              <li>ポイント発行額</li>
              <li>法人カード利用金額</li>
            </ul>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h2>ネットワーク対応精算機・QT-netカード</h2>
          </div>
        </div>

        <div className="row">
          <div className="container cont-08">
            <div className="separate-area">
              <div className="box box-01">
                <picture>
                  <source
                    type="image/webp"
                    srcset="/assets/images/common/service-qtnet-09.webp"
                  />
                  <img
                    className="qtnet-011"
                    src="/assets/images/common/service-qtnet-09.png"
                    height="734"
                    alt=""
                  />
                </picture>
                <p>ネットワーク対応精算機</p>
              </div>

              <div className="box box-02">
                <picture>
                  <source
                    type="image/webp"
                    srcset="/assets/images/common/service-qtnet-10.webp"
                  />
                  <img
                    className="qtnet-012"
                    src="/assets/images/common/service-qtnet-10.png"
                    height="621"
                    alt=""
                  />
                </picture>
                <p>QT-netカード(上)、QT-net法人カード(下)</p>
              </div>

              <div className="box box-03">
                <picture>
                  <source
                    type="image/webp"
                    srcset="/assets/images/common/service-qtnet-11.webp"
                  />
                  <img
                    className="qtnet-013"
                    src="/assets/images/common/service-qtnet-11.png"
                    height="732"
                    alt=""
                  />
                </picture>
                <p>QT-netモバイルカード</p>
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
        </div>

        <ServiceFooter />
      </div>
    </Layout>
  )
}

export default Qtnet
